
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";
import Mqtt from "./components/mqtt.vue";
import TlnApi from "./components/tlnApi.vue";

@Component({
  inject: [],
  components: {Mqtt, TlnApi}
})
export default class InstructionSending extends Mixins() {
    @ProvideReactive() currentTab = "1";
}

