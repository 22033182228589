// websocket实例
let websocket = null;

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 */
export const connectWebsocket  = (url: string, successCallback: Function) => {
  const wsUrl = url;
  // 判断浏览器是否支持websocket
  if(!window.WebSocket){
    console.log('您的浏览器不支持websocket');
    return;
  }
  try {
    websocket = new WebSocket(wsUrl);
    //监听websocket各种状态
    initWsEventHandle(successCallback);
  } catch (e) {
    console.log(e);
  }
}

const initWsEventHandle = (successCallback: Function) => {
  //连接成功建立的回调方法
  websocket.onopen = () => {
    console.log("连接成功")
  };

  //接收到消息的回调方法
  websocket.onmessage = ($event: any) => {
    //接收数据，抛出
    successCallback($event.data);
  };

  //连接关闭的回调方法
  websocket.onclose = (e) => {
    console.log("连接关闭", e)
  };

  //连接发生错误的回调方法
  websocket.onerror = () => {
    console.log("连接错误")
  }
}

/**
 * websocket发送数据
 * @param {Object} sendData 发送的数据
 */
export const sendMessage = (sendData: object) => {
  websocket.send(JSON.stringify(sendData));
}

/**
 * websocket关闭连接
 * @param {Object} sendData 发送的数据
 */
export const closeWebsocket = () => {
  websocket.close();
}