export enum StatusEnum {
    "离线",
    "在线",
    "获取失败",
    "未激活",
}

export enum gzcStatusEnum {
    "空闲中",
    "工作中",
    "",
    "暂停中",
}