
import { Mixins, Component, Ref, InjectReactive, Watch } from "vue-property-decorator";
import { Form } from "element-ui/types";
import { connectWebsocket, sendMessage, closeWebsocket } from "@/utils/websocket";
import dayjs from "dayjs";
import JsonViewer from 'vue-json-viewer';
import { gzcStatusEnum } from "@/enum/device.enum";

const curDomain = `${window.location.origin}`;

@Component({
  components: { JsonViewer }
})
export default class tlnApi extends Mixins() {
  @Ref("validateForm") formRef: Form;
  @InjectReactive() currentTab !: number;
  form = {
    deviceCodes: [
      { deviceCode: "" },
    ],
    orderNo: "",
    action: "",
    workTime: "",
    qrcodeTxt: "",
    userName: "",
    mobile: "",
  };

  rules = {
    deviceCode: [{ required: true, message: "请输入设备编码", trigger: "blur" }],
    orderNo: [{ required: true, message: "请输入订单号", trigger: "blur" }],
    action: [{ required: true, message: "请选择需要发送的指令", trigger: "change" }],
    workTime: [{ required: true, message: "请输入工作时间", trigger: "blur" }],
    qrcodeTxt: [{ required: true, message: "请输入二维码内容", trigger: "blur" }],
    userName: [{ required: true, message: "请输入启动用户名称", trigger: "blur" }],
    mobile: [{ required: true, message: "请输入启动用户手机号", trigger: "blur" }],
  };

  actionList = [
    { value: "A1", label: "A1：启动设备" },
    { value: "A2", label: "A2：停止设备" },
    { value: "A3", label: "A3：暂停计时（设备暂停工作）" },
    { value: "A4", label: "A4：开始计时（设备开始工作）" },
    { value: "B2", label: "B2：读取设备状态" },
    { value: "FF01", label: "FF01：批量查询设备信息" },
    { value: "FF02", label: "FF02：更新设备二维码" },
    { value: "FF03", label: "FF03：设置接收网络动态", disabled: true },
  ];

  backMessage = [];
  deviceStatus = "";

  destroyed() {
    closeWebsocket();
  }

  initSocket() {
    const clientId = Math.random().toString(36).slice(2);
    const baseUrl = curDomain == "https://www.yyznmd.cn" ? curDomain : "https://test.yyznmd.cn";
    const wsUrl = `${baseUrl}/third_socket/ws/${clientId}`;
    connectWebsocket(wsUrl, this.onBackMessage);
  }

  onBackMessage(value: string) {
    const backMessage = JSON.parse(JSON.parse(value));
    backMessage.message = JSON.parse(backMessage.message);
    backMessage.timestamp = dayjs(backMessage.timestamp).format("YYYY-MM-DD HH:mm:ss");
    this.backMessage.push(backMessage);
    if (backMessage.message.action == "B2") {
      this.deviceStatus = gzcStatusEnum[backMessage.message.data.workStatus];
    }
  }

  addDeviceCode() {
    if (this.form.deviceCodes.length >= 25) {
      this.$message.error("一次最多查询25个设备编码");
      return;
    }
    this.form.deviceCodes.push({ deviceCode: "" });
  }

  delDeviceCode(index) {
    this.form.deviceCodes.splice(index, 1);
  }

  send() {
    this.formRef.validate().then(() => {
      const sendData = this.formatSendData();
      sendMessage(sendData);
    });
  }

  clear() {
    this.backMessage = [];
  }

  formatSendData() {
    let sendData = {};
    if (this.form.action == "A1") {
      sendData = {
        "action": this.form.action,
        "data": {
          "orderNo": this.form.orderNo,
          "deviceCode": this.form.deviceCodes.map(item => item.deviceCode)[0],
          "workTime": this.form.workTime,
          "ext": {
            "userName": this.form.userName,
            "mobile": this.form.mobile,
          }
        }
      };
    }
    if (this.form.action == "A2" || this.form.action == "A3" || this.form.action == "A4") {
      sendData = {
        "action": this.form.action,
        "data": {
          "orderNo": this.form.orderNo,
          "deviceCode": this.form.deviceCodes.map(item => item.deviceCode)[0],
          "ext": {
            "userName": this.form.userName,
            "mobile": this.form.mobile,
          }
        }
      };
    }
    if (this.form.action == "B2") {
      sendData = {
        "action": this.form.action,
        "data": {
          "deviceCode": this.form.deviceCodes.map(item => item.deviceCode)[0],
        }
      };
    }
    if (this.form.action == "FF01") {
      sendData = {
        "action": this.form.action,
        "data": {
          "deviceCode": this.form.deviceCodes.map(item => item.deviceCode),
        }
      };
    }
    if (this.form.action == "FF02") {
      sendData = {
        "action": this.form.action,
        "data": {
          "deviceCode": this.form.deviceCodes.map(item => item.deviceCode)[0],
          "qrcodeTxt": this.form.qrcodeTxt,
        }
      };
    }
    return sendData;
  }

  getDeviceStatus() {
    if (!this.form.deviceCodes[0].deviceCode || this.form.deviceCodes.length > 1) {
      this.deviceStatus = "";
      return;
    }
    const sendData = {
      "action": "B2",
      "data": {
        "deviceCode": this.form.deviceCodes[0].deviceCode,
      }
    };
    sendMessage(sendData);
  }

  @Watch("currentTab")
  changeTab(tab) {
    if (tab == 1) {
      closeWebsocket();
      this.clear();
      this.formRef.resetFields();
      this.deviceStatus = "";
    }
    if (tab == 2) {
      this.initSocket();
    }
  }
}
